import { secondsToTimeStr, formatDate } from "@/utils";

export const PRODUCT_ID_BATTEN_STD = 9437441
export const PRODUCT_ID_ENLIGHTING_MIN = 9437184  //0x900000
export const PRODUCT_ID_ENLIGHTING_MAX = 9502719  //0x90FFFF

export const PRODUCT_ID_ENOCEAN_EMSIB = 16770304
export const PRODUCT_ID_THINGSEE_AIR = 16711681

export const PRODUCT_ID_FUJITSU_FWM8BLZ08C = 0xFC1083;

export const TEST_STATUS_MAP = {
    '-1': 'Ongoing...',
    0: 'Success',
    1: 'LED Fault',
    2: 'Power Loss',
    3: 'Battery Fault',
    4: 'Cancelled',
    5: 'Battery Charging',
    6: 'enLink Fault',
    7: 'Unresponsive',
    255: 'No Status'
}

export const TEST_TYPE_MAP = {
    1: 'Function',
    2: 'Duration',
    3: 'Commissioning',
    255: 'No Test',
}
export const SENSOR_SENSITIVITY_MAP = {
    0: 'high',
    1: 'medium',
    2: 'low',
    3: 'veryLow'
}

export const POWER_STATUS_MAP = {
    '-1': 'Boot Up',
    0: 'Power Recover',
    1: 'Power Outage'
}

export const TEST_STATUS_FAULTS = new Set([1, 2, 3, 6, 7]);

const measurementFormatter = value => {
    return value !== null ? value.toFixed(1) : "";
}
export const NODE_TEST_TABLE_LABELS = [
    {
        key: 'type',
        label: 'Test'
    },
    {
        key: 'status',
        label: 'Test Status'
    },
    {
        key: 'startTime',
        label: 'Test Start Time',
        sortable: true
    },
    {
        key: 'endTime',
        label: 'Test End Time',
        sortable: true
    },
    {
        key: 'batSlope',
        label: 'Battery Slope',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'initialBatTemp',
        label: 'Initial Battery Temp.',
        formatter: value => {
          return value ? value.toFixed(1) : "-"
        },
        sortable: true

    },
    {
        key: 'finalBatTemp',
        label: 'Final Battery Temp.',
        formatter: value => {
          return value ? value.toFixed(1) : "-"
        },
        sortable: true
    },
    {
        key: 'initialBatVolt',
        label: 'Initial Battery Voltage',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'finalBatVolt',
        label: 'Final Battery Voltage',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'led1Current',
        label: 'LED 1 Current',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'led1Volt',
        label: 'LED 1 Voltage',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'led2Current',
        label: 'LED 2 Current',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'led2Volt',
        label: 'LED 2 Voltage',
        formatter: measurementFormatter,
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions'
    }
];

export const TEST_STATUS = [
    {
        statusCode: -1,
        statusLabel: 'Ongoing...'
    }, {
        statusCode: 0,
        statusLabel: 'Ok'
    }, {
        statusCode: 1,
        statusLabel: 'LED Fault'
    }, {
        statusCode: 2,
        statusLabel: 'Power Loss'
    }, {
        statusCode: 3,
        statusLabel: 'Low Battery'
    }
];

export const NODE_LOGS_TABLE_LABELS_EMG = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => {
          return formatDate(value);
        }
    },
    {
        key: 'mainsRunTime',
        label: 'Uptime',
        formatter: value => {
            return secondsToTimeStr(value);
        }
    },
    {
        key: 'minimumTemperature',
        label: 'Min. Temperature °C',
        sortable: true,
        formatter: value => {
            return value ? (value / 100).toFixed(1) : "-";
        }
    },
    {
        key: 'maximumTemperature',
        label: 'Max. Temperature °C',
        sortable: true,
        formatter: value => {
            return value ? (value / 100).toFixed(1) : "-";
        }
    },
    {
        key: 'chargeCycles',
        label: 'Charge Cycles',
        formatter: value => {
            return value ? value : "-"
        }
    },
    {
        key: 'emgLedRuntime',
        label: 'LED Uptime',
        formatter: value => {
            return value ? secondsToTimeStr(value) : '-'
        }
    }
]

export const NODE_LOGS_TABLE_LABELS_STD = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => {
            return formatDate(value);
        }
    },
    {
        key: 'mainsRunTime',
        label: 'Uptime',
        formatter: value => {
            return secondsToTimeStr(value);
        }
    }
]

export const NODE_LOGS_TABLE_LABELS_EMSIB = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => {
            return formatDate(value);
        }
    },
    {
        key: 'temperature',
        label: 'Temperature (°C)',
        formatter: value => {
            return value / 100;
        }
    },
    {
        key: 'relativeHumidity',
        label: 'RH',
        formatter: value => {
            return (value / 100).toFixed(1).toString() + " %";
        }
    },
    {
        key: 'sensorIllumination',
        label: 'Illumination (Lux)',
        formatter: value => {
            return value;
        }
    }
]

export const NODE_LOGS_TABLE_THINGSEE_AIR = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => {
            return formatDate(value);
        }
    },
    {
        key: 'temperature',
        label: 'Temperature (°C)',
        formatter: value => {
            return value ? value / 100 : "-";
        }
    },
    {
        key: 'relativeHumidity',
        label: 'RH',
        formatter: value => {
            return value ? (value / 100).toFixed(1).toString() + " %" : "-";
        }
    },
    {
        key: 'sensorIllumination',
        label: 'Illumination (Lux)',
        formatter: value => {
            return value ? value / 100 : "-";
        }
    },
    {
        key: 'airPressure',
        label: 'Amb. Pressure (kPa)',
        formatter: value => {
            return value ? value / 10 : "-";
        }
    },
    {
        key: 'tvoc',
        label: 'TVOC (ppb)',
        formatter: value => {
            return value ? value : "-"
        }
    }
]

export const NODE_LOGS_TABLE_FUJITSU_FWM8BLZ08C = [
    {
        key: 'time',
        label: 'Time',
        sortable: true,
        formatter: value => {
            return formatDate(value);
        }
    },
    {
        key: 'temperature',
        label: 'Temperature (°C)',
        formatter: value => {
            return value ? value / 100 : "-";
        }
    },
    {
        key: 'relativeHumidity',
        label: 'RH',
        formatter: value => {
            return value ? (value / 100).toFixed(1).toString() + " %" : "-";
        }
    },
    {
        key: 'sensorIllumination',
        label: 'Illumination (Lux)',
        formatter: value => {
            return value ? value : "-";
        }
    },
    {
        key: 'airPressure',
        label: 'Amb. Pressure (kPa)',
        formatter: value => {
            return value ? value / 10 : "-";
        }
    },
    {
        key: 'noise',
        label: 'Sound (dB)',
    },
    {
        key: 'co2',
        label: 'CO2 (ppm)',
    }
]

export const NODE_MOTION_EVENTS_TABLE_LABELS = [
    {
        key: 'start_time',
        label: 'Start Time',
    },
    {
        key: 'end_time',
        label: 'End Time'
    },
    {
        key: 'ended_from_emergency',
        label: 'Emergency Stop'
    }
]

export const BATTERY_REPLACEMENT_EVENT_TABLE = [
    {
        key: 'time',
        label: 'Replacement Time',
    },
    {
        key: 'chargeCycleCount',
        label: 'Charge Cycles',
    }
]

export const NODE_POWER_EVENTS_TABLE_LABELS = [
    {
        key: 'time',
        label: 'Time',
    },
    {
        key: 'status',
        label: 'Status'
    }
]

export const SITE_TALBE_LABELS = [
    {
        key: 'site',
        label: 'Site',
        sortable: true
    },
    {
        key: 'status',
        label: 'Site Status',
        sortable: false
    },
    {
        key: 'gateway_connectivity',
        label: 'Connectivity',
        sortable: false
    }
]

export const SITE_NODES_LIST_TABLE_LABELS = [
    {
        key: 'device',
        sortable: true
    }, {
        key: 'last_test'
    }, {
        key: 'date',
        sortable: true
    }, {
        key: 'outcome'
    }, {
        key: 'actions'
    }, {
        key: 'product'
    }
]

export const SITE_REPORTING_LUMINAIRE_DETAILS_LABELS = [
    {
        key: 'assetNo',
        label: 'Asset No.'
    },
    {
        key: 'name',
        label: 'Name'
    },
    {
        key: 'location',
        label: 'Location'
    },
    {
        key: 'product.maintained',
        label: 'Mode',
        formatter: value => {
            return value ? "M" : "N/M";
        }
    },
    {
        key: 'product.id',
        label: 'Lamp'

    },
    {
        key: 'product.name',
        label: 'Brand'
    },
    {
        key: 'sbcbNo',
        label: 'Switchboard / Circuit breaker No'
    }
]

export const SITE_REPORTING_LUMINAIRE_TEST_REPORT_LABELS = [
    {
        key: 'assetNo',
        label: 'Asset No.'
    },
    {
        key: 'lastCompletedTest.startTime',
        label: 'Start Time',
        formatter: 'formatDate'
    },
    {
        key: 'lastCompletedTest.endTime',
        label: 'End Time',
        formatter: 'formatDate'
    },
    {
        key: 'duration',
        label: 'Duration'
    },
    {
        key: 'lastCompletedTest.status',
        label: 'Outcome',
        formatter: 'formatStatus'
    }
]

export const USER_ROLE_MAP = {
    0: 'general',
    1: 'admin',
}