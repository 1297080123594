import Vue from 'vue'
import App from './App.vue'
import { createProvider } from './vue-apollo'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router';
import Router from 'vue-router'
import "./styles/style.scss";
import Notifications from "vue-notification";
import store from './store'
import axios from "axios";
import VCalendar from 'v-calendar';
import FullCalendar from "vue-full-calendar";
import { GlobalFuncs } from "@/utils.js";

import "fullcalendar/dist/fullcalendar.min.css";
// import router from './router'
// 
Vue.use(FullCalendar);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.use(VCalendar)
Vue.use(GlobalFuncs);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
Vue.config.productionTip = false

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.detail) {
      app.$notify({
        group: "error",
        type: "error",
        title: `${error.response.status}: ${error.response.statusText}`,
        text: `${error?.response?.data?.detail}`
      });

    } else {
      app.$notify({
        group: "error",
        type: "error",
        title: `${error.response.status}: ${error.response.statusText}`,
        text: `${error.code}: ${error.message}`
      });
    }
  }
);


const app = new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

export default app;