import { actionTypes } from "./actions";
export const mutations = {
	[actionTypes.SET_USERS]: (state, payload) => {
		localStorage.setItem("users", JSON.stringify(payload));
		state.users = payload;
	},
  [actionTypes.SET_USER]: (state, payload) => {
		localStorage.setItem("user", JSON.stringify(payload));
		state.user = payload;
	},
	[actionTypes.SET_SITES]: (state, payload) => {
		localStorage.setItem("sites", JSON.stringify(payload));
		state.sites = payload;
	},
	[actionTypes.SET_SITE]: (state, payload) => {
		state.site = payload;
	},
  [actionTypes.SET_TENANTS]: (state, payload) => {
		localStorage.setItem("tenants", JSON.stringify(payload));
		state.tenants = payload;
	},
  [actionTypes.SET_TENANT]: (state, payload) => {
		localStorage.setItem("tenant", JSON.stringify(payload));
		state.tenant = payload;
	},
  [actionTypes.SET_TENANTSNAME]: (state, payload) => {
		localStorage.setItem("tenantsName", JSON.stringify(payload));
		state.tenantsName = payload;
	},
  [actionTypes.SET_DEVICEGROUPS]: (state, payload) => {
		localStorage.setItem("devicegroups", JSON.stringify(payload));
		state.devicegroups = payload;
	},
  [actionTypes.SET_DEVICEGROUP]: (state, payload) => {
		localStorage.setItem("devicegroup", JSON.stringify(payload));
		state.devicegroup = payload;
	},
  [actionTypes.SET_DEVICEGROUPSNAME]: (state, payload) => {
		localStorage.setItem("devicegroupsName", JSON.stringify(payload));
		state.devicegroupsName = payload;
	},
  [actionTypes.SET_SITESNAME]: (state, payload) => {
		localStorage.setItem("sitesName", JSON.stringify(payload));
		state.sitesName = payload;
	},
  [actionTypes.SET_ENTITIES]: (state, payload) => {
		localStorage.setItem("entities", JSON.stringify(payload));
    state.entities = payload;
	},
  [actionTypes.SET_ENTITY]: (state, payload) => {
		localStorage.setItem("entity", JSON.stringify(payload));
		state.entity = payload;
	},
	[actionTypes.NODE_TESTS]: (state, payload) => {
		state['nodeTests'] = payload;
	},
	[actionTypes.SITE_NODES]: (state, payload) => {
		state['siteNodes'] = payload;
	},
  [actionTypes.SITE_PRODUCTLIST]: (state, payload) => {
		state['siteProductList'] = payload;
	},
  [actionTypes.SITE_LOCATIONLIST]: (state, payload) => {
		state['siteLocationList'] = payload;
	},
	[actionTypes.SET_NODES_DATA]: (state, payload) => {
		state.nodeData = payload;
	},
	[actionTypes.SET_HAS_TEST_REQUEST]: (state, [nodeId, hasTestRequest]) => {
		state.nodeData[nodeId].hasTestRequest = hasTestRequest;
	},
	[actionTypes.SET_NODE_LAST_TEST_INFO]: (state, [nodeId, test]) => {
		let device = state.siteNodes.rows.find(it => it.id === nodeId);
		if (device) {
			device.last_test = test.type
			device.date = test.startTime ? test.startTime : '_'
			device.outcome = test.status
		}
	},
	[actionTypes.SET_HAS_CANCEL_REQUEST]: (state, [nodeId, hasCancelRequest]) => {
		state.nodeData[nodeId].hasCancelRequest = hasCancelRequest;
	},
	[actionTypes.GET_SCHEDULES]: (state, payload) => {
		state['schedulesByDevice'] = payload;
	},
	[actionTypes.SET_MOTION_EVENTS]: (state, payload) => {
		state.motionEvents = payload;
	},
	[actionTypes.SET_BATTERY_REPLACEMENT_EVENTS]: (state, payload) => {
		state.batteryReplacementEvents = payload;
	},
  [actionTypes.SET_POWER_EVENTS]: (state, payload) => {
		state.powerEvents = payload;
	},
	[actionTypes.SET_SITE_STATS]: (state, payload) => {
		state['siteFaultSummary'] = payload;
	},
	[actionTypes.SET_SERVER_BLE_VERSION]: (state, payload) => {
		state['serverBLEVersion'] = payload;
	},
	[actionTypes.SET_CURRENT_TIMEZONE]: (state, payload) => {
		state['siteTimezone'] = payload;
	},
	[actionTypes.SET_UPCOMING_TESTS]: (state, payload) => {
		state['siteUpcomingTests'] = payload;
	},
	[actionTypes.SET_DEVICE_LOGS_STATE]: (state, payload) => {
		state['deviceLogsComponent'].nodeId = payload.nodeId;
		state['deviceLogsComponent'].nodeLogs = payload.nodeLogs;
	},
  [actionTypes.SET_SITENODESLIST_USERCONTROL]: (state, payload) => {
		state.siteNodesList_UserControl = payload;
	},
};
